import React, { useEffect, useState } from "react";
import { generateCaptchaText } from "./generateCaptchaText";
import * as Yup from "yup";
import { t } from "i18next";
import { Field } from "formik";
import { FaSyncAlt } from 'react-icons/fa';

const CustomCaptcha = ({ onChange, touched, errors, resetCaptcha  }) => {
  const [inputValue, setInputValue] = useState("");
  const [captchaText, setCaptchaText] = useState(generateCaptchaText());

  const handleRefreshCaptcha = () => {
    setCaptchaText(generateCaptchaText());
    setInputValue("");
    onChange(""); // Clear the parent component's captcha value on refresh
  };

  const handleInputChange = (e) => {
    // console.log(e.target.value);
    // console.log(captchaText);
    setInputValue(e.target.value);
    onChange(e.target.value === captchaText);
  };

  //  Reset the captcha when the resetCaptcha prop changes
  useEffect(() => {
    if (resetCaptcha) {
      handleRefreshCaptcha();
    }
  }, [resetCaptcha]);

  const validationSchema = Yup.object().shape({
    captcha: Yup.string().required("Captcha is required"),
  });

  return (
        <div className="form-inline my-2">
            <div className="form-group mb-2">
                <label  className="sr-only">{t("login.captchaLabel")}:</label>
                &nbsp;
                &nbsp;
                <label  className="sr-only py-2 bg-dark captcha-text px-2 rounded">{captchaText}</label>
            </div>
            <div className="form-group d-flex align-items-center mb-2">
            <div className="flex-grow-1">
                <Field
                type="text"
                id="captcha"
                name="captcha"
                required="required"
                value={inputValue}
                onChange={handleInputChange}
                className={`form-control ${
                    touched.captcha && errors.captcha ? "is-invalid" : ""
                }`}
                />
                {touched.captcha && errors.captcha && (
                <div className="invalid-feedback">{errors.captcha}</div>
                )}
            </div>
            
            <button
                type="button"
                className="btn btn-success ms-2 me-2"
                onClick={handleRefreshCaptcha}
            >
            <FaSyncAlt /> 
            </button>
            </div>

        </div>
    
  );
};

export default CustomCaptcha;
