



//Deployment(STATIC):
export const API_BASE_URL = 'https://api.static-holding.com/public/';
export const API_Image_BASE_URL = 'https://api.static-holding.com/public/';
export const FILES_BASE_URL = "https://api.static-holding.com/public/storage/driver";
export const FRONT_BASE_URL = "https://static-holding.com/";


// export const FRONT_BASE_URL = "https://traffic.afgsoft.net/";

