import React, { useState } from "react";
// import {SearchFa} from "react-icons/fa"
import Brand from "../../../Media/Brand.png"
import { useDispatch, useSelector } from "react-redux";
import { FetchPublicDriverData } from "../../../redux/Public/FetchPublicDriverData";
import { BiSearch } from "react-icons/bi";
import { t } from "i18next";
const SearchBox = () => {

    const [search,setSearch] = useState("");

    const [isValid, setIsValid] = useState(true); // State to track validation


    const dispatch = useDispatch();
    const message = useSelector((state) => state.public_driver.message);
    const type = useSelector((state) => state.public_driver.type);

    const handleInputChange = (e) => {
      const inputValue = e.target.value;
      // Validate the input using a regular expression
      const isValidInput = /^[\d-]*$/.test(inputValue);
      setIsValid(isValidInput);
  
      // Set the search value only if it's a valid input
      if (isValidInput) {
        setSearch(inputValue);
      }

    };


    const findVehicle = ()=>
    {
        // console.log("Searchg:");
        // console.log(search);
        if (isValid && search) {
          dispatch(FetchPublicDriverData(search));
        }

        // if(search)
        // {
        //     dispatch(FetchPublicDriverData(search));
        // }
    }
  return (
    <>
    <div className="search-container col-lg-4 col-md-4 col-sm-12">
        <div className="logo-wropper">
            <img src={Brand} alt="logo" className="logo" />
        </div>
        <p>صفحه جستجوی موتر های تغییر رنگ</p>
        <input type="text" placeholder="نمبر پلیت را بنویسید" className="search-box" 
        name="search"
        value={search}
        // onChange={e=>setSearch(e.target.value)}
        onChange={handleInputChange}
        />
       
        <button onClick={findVehicle}  className={`search-button`} ><BiSearch className="fs-5"/></button>
        {
        message && type==="danger" &&(
        message && <div className={`alert alert-${type} text-center py-2 my-3 `}>
        موتر به این نمبر پلیت موجود نیست!
        </div>
        
        )}
    </div>
    </>
  );
  
};

export default SearchBox;
